<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">单元列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加单元</el-button>
		</div>
		<el-table :data="list" style="width: 100%" stripe border>
			<el-table-column type="index" align="center" width="50" />
			<el-table-column align="center" prop="unitName" label="名称" />
			<el-table-column align="center" prop="grade.gradeName" label="年级" />
			<el-table-column align="center" prop="semester.semesterName" label="学期" />
			<el-table-column align="center" prop="subject.subjectName" label="学科" />
			<el-table-column align="center" prop="press.pressName" label="出版社" />
			<el-table-column label='操作' align="center">
				<template #default="scope">
					<el-button size="small" type="primary" @click="edits(scope.row)">修改
					</el-button>
					<el-button size="small" type="danger" @click="deletes(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="elpagination">
			<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
		</div>
	</div>



	<el-dialog v-model="isadd" title="添加单元" width="30%">
		<div class="row">
			<div class="row-title">单元名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.unitName" placeholder="请输入要添加的单元名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">描述<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.unitDesc" placeholder="请输入描述" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.gradeId" style="width: 100%;" clearable>
				<el-option v-for="item in gradeList" :label="item.gradeName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学期<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.semesterId" style="width: 100%;" clearable>
				<el-option v-for="item in semesterList" :label="item.semesterName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学科<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.subjectId" style="width: 100%;" clearable>
				<el-option v-for="item in subjectList" :label="item.subjectName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">出版社<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.pressId" style="width: 100%;" clearable>
				<el-option v-for="item in pressList" :label="item.pressName" :value="item.id"></el-option>
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isadd = false">取消</el-button>
				<el-button type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog v-model="isedit" title="修改单元" width="30%">
		<div class="row">
			<div class="row-title">单元名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.unitName" placeholder="请输入要添加的单元名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">描述<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.unitDesc" placeholder="请输入描述" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.gradeId" style="width: 100%;" clearable>
				<el-option v-for="item in gradeList" :label="item.gradeName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学期<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.semesterId" style="width: 100%;" clearable>
				<el-option v-for="item in semesterList" :label="item.semesterName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">学科<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.subjectId" style="width: 100%;" clearable>
				<el-option v-for="item in subjectList" :label="item.subjectName" :value="item.id"></el-option>
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">出版社<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.pressId" style="width: 100%;" clearable>
				<el-option v-for="item in pressList" :label="item.pressName" :value="item.id"></el-option>
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isedit = false">取消</el-button>
				<el-button type="primary" @click="submitedit" :loading="loading">修改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		unitlist,
		unitadd,
		unitedit,
		unitdelete,
		presseslist,
		semesterslist,
		subjectslist,
		gradeslist
	} from "../../util/api"
	export default {
		data() {
			return {
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null,
				isadd: false,
				isedit: false,
				addjson: {
					gradeId: null, //年级ID
					pressId: null, //出版社ID
					semesterId: null, //学期ID
					subjectId: null, //学科ID
					unitDesc: null, //单元描述
					unitName: null //单元名称
				},
				editjson: {
					id: null,
					gradeId: null, //年级ID
					pressId: null, //出版社ID
					semesterId: null, //学期ID
					subjectId: null, //学科ID
					unitDesc: null, //单元描述
					unitName: null //单元名称
				},
				loading: false,
				gradeList: null, //年级列表
				pressList: null, //出版社列表
				semesterList: null, //学期列表
				subjectList: null //学科列表
			}
		},
		created() {
			this.getlist()
			this.getgradeList() //年级
			this.getpressList() //出版社
			this.getsemesterList() //学期
			this.getsubjectList() //学科
		},
		methods: {
			submitedit() {
				if (!this.editjson.unitName) {
					this.$message({
						type: 'warning',
						message: '请输入单元名称',
					})
				} else if (!this.editjson.unitDesc) {
					this.$message({
						type: 'warning',
						message: '请输入单元描述',
					})
				} else if (!this.editjson.gradeId) {
					this.$message({
						type: 'warning',
						message: '请选择年级',
					})
				} else if (!this.editjson.semesterId) {
					this.$message({
						type: 'warning',
						message: '请选择学期',
					})
				} else if (!this.editjson.subjectId) {
					this.$message({
						type: 'warning',
						message: '请选择学科',
					})
				} else if (!this.editjson.pressId) {
					this.$message({
						type: 'warning',
						message: '请选择出版社',
					})
				} else {
					this.loading = true
					unitedit(this.editjson).then((res)=>{
						this.loading = false
						this.isedit = false
						this.$message({
							type: 'success',
							message: '修改成功',
						})
						this.getlist()
					}).catch((error)=>{
						this.loading = false
					})
				}
			},
			edits(row) {
				this.editjson = {
					id: row.id,
					gradeId: row.gradeId, //年级ID
					pressId: row.pressId, //出版社ID
					semesterId: row.semesterId, //学期ID
					subjectId: row.subjectId, //学科ID
					unitDesc: row.unitDesc, //单元描述
					unitName: row.unitName //单元名称
				}
				this.isedit = true
			},
			deletes(row) {
				this.$confirm('是否删除单元“' + row.unitName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					unitdelete(row.id).then((res)=>{
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			submitadd() {
				if (!this.addjson.unitName) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.addjson.unitDesc) {
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				} else if (!this.addjson.gradeId) {
					this.$message({
						type: 'warning',
						message: '请选择年级',
					})
				} else if (!this.addjson.semesterId) {
					this.$message({
						type: 'warning',
						message: '请选择学期',
					})
				} else if (!this.addjson.subjectId) {
					this.$message({
						type: 'warning',
						message: '请选择学科',
					})
				} else if (!this.addjson.pressId) {
					this.$message({
						type: 'warning',
						message: '请选择出版社',
					})
				} else {
					this.loading = true
					unitadd(this.addjson).then((res)=>{
						this.loading = false
						this.isadd = false
						this.$message({
							type: 'success',
							message: '添加成功',
						})
						this.addjson = {
							gradeId: null, //年级ID
							pressId: null, //出版社ID
							semesterId: null, //学期ID
							subjectId: null, //学科ID
							unitDesc: null, //单元描述
							unitName: null //单元名称
						}
						this.getlist()
					}).catch((error)=>{
						this.loading = false
					})
				}
			},
			getlist() { //获取列表
				unitlist({
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then((res)=>{
					this.list = res.data.list
					this.pages = res.data.pages
				})
			},
			getgradeList() { //年级列表
				gradeslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.gradeList = res.data.list
				})
			},
			getpressList() { //出版社列表
				presseslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.pressList = res.data.list
				})
			},
			getsemesterList() { //学期列表
				semesterslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.semesterList = res.data.list
				})
			},
			getsubjectList() { //学科列表
				subjectslist({
					pageNum: 1,
					pageSize: 99999
				}).then((res) => {
					this.subjectList = res.data.list
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>

</style>
